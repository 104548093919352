import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import Sidebar from "../../components/Sidebar"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function RealEstateLaw({ data }) {
  return (
    <Layout>
      <GatsbySeo
        title="Charleston South Carolina Real Estate Lawyers | Tecklenburg, Jenkins & Jacoby, LLC"
        description="Tecklenburg, Jenkins & Jacoby, LLC represents commercial owners, developers
            and operators in real estate transactions. Real estate transactions are complex. An experienced and seasoned
            real estate attorney can help you understand the documents and the
            risks associated with the transaction."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/practice-areas/south-carolina-real-estate-law/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <Sidebar data={data} />
        </section>
        <section className={styles.content}>
          <h1>Charleston, SC Real Estate Lawyers</h1>
          {/* <h2>REAL ESTATE LAW</h2> */}
          <h2>Confident and Knowledgeable Real Estate Attorneys</h2>
          <p>
            Tecklenburg, Jenkins & Jacoby, LLC represents commercial owners,
            developers and operators in real estate transactions.
          </p>
          <h2>Attention to Detail in Complex Real Estate Transactions</h2>
          <p>
            Real estate transactions are complex. An experienced and seasoned
            real estate attorney can help you understand the documents and the
            risks associated with the transaction. At the law offices of
            Tecklenburg, Jenkins & Jacoby, we help our clients in preparing and
            understanding real estate contracts and documents. We draft, review,
            and negotiate various types of contracts and documents that
            accompany the legal transfer of real property. Our skilled attorneys
            assist clients through all phases of real estate transactions, such
            as:
          </p>
          <ul>
            <li>
              <h3>Buy-Sell Agreements</h3>
            </li>
            <li>
              <h3>Easements</h3>
            </li>
            <li>
              <h3>Partnership/Corporate Asset Protection</h3>
            </li>
            <li>
              <h3>Entity Formation</h3>
            </li>
            <li>
              <h3>Loan Negotiations and Workouts</h3>
            </li>
            <li>
              <h3>Retail, Office, Industrial & Leases</h3>
            </li>
            <li>
              <h3>Ownership and Title Issues</h3>
            </li>
            <li>
              <h3>Brokerage Matters</h3>
            </li>
            <li>
              <h3>Public-Private Partnership Agreements</h3>
            </li>
            <li>
              <h3>Development</h3>
            </li>
            <li>
              <h3>Environmental Issues</h3>
            </li>
          </ul>
          <h2>
            Thorough and Unwavering Representation in Real Estate{" "}
            <Link to="/practice-areas/south-carolina-litigation">
              Litigation
            </Link>
          </h2>
          <p>
            Real estate litigation requires substantial knowledge about property
            laws and civil litigation, as well as a dynamic courtroom presence.
            At Tecklenburg, Jenkins & Jacoby, our attorneys possess the
            specialized knowledge necessary to successfully litigate your real
            estate disputes.
          </p>
          <p>
            Sometimes the issue is the property itself; other issues could be
            boundary lines of neighboring lots, or the property’s natural
            resources. Due to the complexity of real estate transactions and the
            number of parties often involved, occasionally disputes arise. Our
            experienced real estate attorneys protect the interests of our
            clients in various real estate disputes, including:
          </p>
          <ul>
            <li>
              <h3>Commercial Real Estate Litigation</h3>
            </li>
            <li>
              <h3>Quiet Title Actions</h3>
            </li>
            <li>
              <h3>Boundary Disputes</h3>
            </li>
            <li>
              <h3>Questions of Title</h3>
            </li>
            <li>
              <h3>Partition Actions</h3>
            </li>
            <li>
              <h3>Mechanic’s Liens</h3>
            </li>
            <li>
              <h3>Fraudulent Lien Claims & Removal</h3>
            </li>
            <li>
              <h3>Foreclosures</h3>
            </li>
            <li>
              <h3>Abandonments of Rights of Way</h3>
            </li>
            <li>
              <h3>Environmental Issues</h3>
            </li>
          </ul>
          <h2>Representing Homeowners' Associations</h2>
          <p>
            Our attorneys are experienced in the preparation of clear, legally
            sound homeowners' association covenants that establish the rights
            and responsibilities of all parties. Tecklenburg, Jenkins & Jacoby
            also aids HOA’s with matters such as:
          </p>
          <ul>
            <li>
              <h3>
                Declarations of Covenants, Conditions and Restrictions for
                Subdivisions, Townhomes, Condominiums and Mixed Use Projects
              </h3>
            </li>
            <li>
              <h3>Homeowner Association Rules and Liens Enforcement</h3>
            </li>
            <li>
              <h3>Delinquent HOA Dues Collection</h3>
            </li>
            <li>
              <h3>HOA Rules and Municipal Ordinances</h3>
            </li>
            <li>
              <h3>Contracts with Employees and Vendors</h3>
            </li>
            <li>
              <h3>
                Board Representation Before Local and State Government Agencies
              </h3>
            </li>
            <li>
              <h3>Faulty Construction Litigation</h3>
            </li>
            <li>
              <h3>Insurance Programs Evaluation</h3>
            </li>
          </ul>
          <h2>Seek Experienced Representation</h2>
          <p>
            Our experienced and knowledgeable attorneys represent clients in
            residential and commercial real estate transactions, as well as a
            full range of real estate litigation. Whether you need assistance at
            the bargaining table or at trial,{" "}
            <Link to="/contact">contact Tecklenburg, Jenkins & Jacoby</Link>,
            LLC for a consultation.
          </p>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query RealEstateLawSidebarImages {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "south-carolina-real-estate-law" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
